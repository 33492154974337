<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="card">
        <DataTable :value="dataimportconfigsetlist" :lazy="true" v-model:selection="selectedData" selectionMode="single"
            :scrollable="true" stripedRows :scrollHeight="sHeight" :loading="loading" scrollDirection="both"
            :paginator="true" :rows="50" :totalRecords="totalRecords" :first="first"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3" @page="listPage($event)">
            <template #header>
                <div class="grid">
                    <div class="col-8">
                        <div class="text-left">
                            <InputText id='searchtargetschemaInput' placeholder="对象" v-model="searchtargetschema"
                                type="text" />&nbsp;&nbsp;&nbsp;&nbsp;
                            <InputText placeholder="对象名称" id='searchtargetschemacnameInput'
                                v-model="searchtargetschemacname" type="text" />
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="text-right">
                            <Button label="查询" icon="pi pi-search" @click="loadData(1)"
                                class="ml-auto" />&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button label="新建" icon="pi pi-plus" @click="createdetail()"
                                class="ml-auto" />&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button label="删除" icon="pi pi-times" @click="deldetail()" class="button-danger" />
                        </div>
                    </div>
                </div>
            </template>
            <Column field="targetschema" header="对象" style="width: 30%" frozen>
                <template #body="slotProps">
                    <Button :label="slotProps.data.targetschema" @click="opendetail(slotProps.data)"
                        class="p-button-link" />
                </template>
            </Column>
            <Column field="targetschemacname" header="对象名称" style="width: 40%"></Column>
            <Column field="describe" header="描述" style="width: 30%"></Column>
        </DataTable>
    </div>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" v-model:visible="editDisplay" :style="{width: '70vw'}" :maximizable="true"
        :modal="true">
        <div class="card p-fluid">
            <h5>基本信息</h5>
            <div class="fluid formgrid grid">
                <div class="field col">
                    <label for="targetschema">目标对象</label>
                    <Dropdown id='targetschema' v-model="currentRow.targetschema" @change="targetschemaChange"
                        :options="dicts['allschemaname']" showClear="true" :filter="true" optionLabel="name"
                        optionValue="code" placeholder="请选择" />
                </div>
                <div class="field col">
                    <label for="describeTextarea">描述</label>
                    <Textarea id='describeTextarea' v-model="currentRow.describe" rows="1" cols="35" />
                    </div>
            </div>
            <Card>
                 <template #title>
                     <div style="font-size:1.3rem;font-weight:400">配置详细</div>
                 </template>
                  <template #content>
                       <TabView v-model:activeIndex="activeIndex">
                            <TabPanel header="数据源">
                                <DataTable :value="currentRow.source" :lazy="false" editMode="cell" @cell-edit-complete="onCellEditComplete" @cell-edit-init="sourceTableCellEditInit" class="editable-cells-table" :scrollable="false" stripedRows scrollDirection="both">
                                    <Column field="sourceschema" header="对象" style="flex-grow:1;">
                                        <template #editor="{ data }">
                                        <Dropdown v-model="data['sourceschema']" @change="sourceschemaChange" :options="dicts['allschemaname']" showClear="true" :filter="true" optionLabel="name" optionValue="code" style="width:280px" placeholder="请选择" />
                                        </template>
                                    </Column>
                                    <Column field="sourcefield" header="条件属性" style="flex-grow:1;">
                                        <template #body="slotProps">
                                        {{analysisBindData(slotProps.data['sourcefield'])}}
                                        </template>
                                        <template #editor="{ data }">
                                            <TreeSelect v-model="data['sourcefield']['selectedValue']" @node-select="sourcefieldtreeNodeSelect(data)" :options="binddataNodes" selectionMode="single" style="width:280px" placeholder="请选择" />
                                        </template>
                                    </Column>
                                    <Column header="操作" style="flex-grow:1;">
                                        <template #body="slotProps">
                                                <Button label="移除" @click="removesource(slotProps.data)" class="p-button-link" />
                                            </template>
                                        </Column>
                                </DataTable>
                            </TabPanel>
                            <TabPanel header="对应关系">
                                    <DataTable :value="currentRow.relation"  v-model:selection="selectedRelationRows" :lazy="false" editMode="cell" @cell-edit-complete="onCellEditComplete" @cell-edit-init="relationTableCellEditInit" class="editable-cells-table" :scrollable="false" stripedRows scrollDirection="both">
                                    <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
                                    <Column field="targetfield" header="目标属性" style="flex-grow:1;">
                                        <template #body="slotProps">
                                        {{analysisBindData(slotProps.data['targetfield'])}}
                                        </template>
                                        <template #editor="{ data }">
                                            <TreeSelect v-model="data['targetfield']['selectedValue']" @node-select="targetfieldtreeNodeSelect(data)" :options="targetbinddataNodes" selectionMode="single" style="width:280px" placeholder="请选择" />
                                        </template>
                                    </Column>
                                    <Column field="sourceschema" header="数据源" style="flex-grow:1;">
                                        <template #editor="{ data }">
                                        <Dropdown v-model="data['sourceschema']" @change="targetsourceschemaChange" :options="getrelationsourceschema()" showClear="true" :filter="true" optionLabel="name" optionValue="code" style="width:280px" placeholder="请选择" />
                                        </template>
                                    </Column>
                                        <Column field="sourcefield" header="源属性" style="flex-grow:1;">
                                        <template #body="slotProps">
                                        {{analysisBindData(slotProps.data['sourcefield'])}}
                                        </template>
                                        <template #editor="{ data }">
                                            <TreeSelect v-model="data['sourcefield']['selectedValue']" @node-select="targetsourcefieldtreeNodeSelect(data)" :options="binddataNodes" selectionMode="single" style="width:280px" placeholder="请选择" />
                                        </template>
                                    </Column>
                                </DataTable>
                            </TabPanel>
                        </TabView>
                  </template>
                  <template #footer>
                       <Button label="新建" class="p-button-outlined" @click="createTabView()" />
                       &nbsp;&nbsp;&nbsp;&nbsp;
                       <Button v-if="activeIndex==1" label="移除" class="p-button-outlined" @click="delRelation()" />
                  </template>
            </Card>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeEdit" />
                <Button label="保存" icon="pi pi-check" @click="submitData" :loading="butLoading" autofocus />
            </div>
        </template>
    </Dialog>
</template>
<script>
    import {
        ref,
    } from 'vue';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import requests from '../../service/newaxios';
    import * as Base64 from 'js-base64';
    import CommonJs from '../../js/Common.js';
    import MessageTip from '../../components/Message';

    export default {
        setup() {
            const confirm = useConfirm();
            return {
                confirm,
            };
        },
        data() {
            return {
                activeIndex: 0,
                targetbinddataNodes: ref(),
                binddataNodes: ref(),
                newId: 0,
                allSchemaName: [],
                selectedData: ref(),
                searchtargetschema: ref(),
                searchtargetschemacname: ref(),
                currentRow: null,
                loading: false,
                butLoading: false,
                editDisplay: false,
                recordsubject: '',
                totalRecords: 0,
                first: 0,
                dataimportconfigsetlist: [],
                allFields: [],
                dicts:{},
                sHeight: '650px',
                selectedRelationRows:[],
            }
        },
        created() {
            MessageTip.initMsg();
            this.sHeight = (window.innerHeight - 300) + "px";
            this.loadDict();
            this.loadData(1);
        },
        methods: {
            getNewId() {
                console.log('getNewId:' + this.newId);
                this.newId = this.newId + 1;
                return -1 * this.newId + '';
            },
            createTabView(){
                if(this.activeIndex==0){
                    this.createSource();
                }else{
                   this.createRelation();
                }
            },
            createSource(){
              var sourceschema='';
              if(this.currentRow.source.length>0){
                  sourceschema=this.currentRow.source[this.currentRow.source.length-1]['sourceschema'];
              }
              this.currentRow.source.push({
                  sourceschema:sourceschema,
                  sourcefield:{fieldname:'',selectedValue:{}},
               });
            },
            delRelation(){
                if(this.selectedRelationRows.length==0){
                    MessageTip.warnmsg('请选择要删除的数据');
                    return;
                }
                for (var j = 0; j < this.selectedRelationRows.length; j++) {
                    for(var i=0;i<this.currentRow.relation.length;i++){
                        if(this.currentRow.relation[i]['sourceschema']==this.selectedRelationRows[j]['sourceschema'] && this.currentRow.relation[i]['targetfield']['fieldname']==this.selectedRelationRows[j]['targetfield']['fieldname'] && this.currentRow.relation[i]['sourcefield']['fieldname']==this.selectedRelationRows[j]['sourcefield']['fieldname']){
                            this.currentRow.relation.splice(i, 1);
                            break;
                        }
                    }
                }
                this.selectedRelationRows=[];
            },
            createRelation(){
                this.currentRow.relation.push({
                  targetfield:{fieldname:'',selectedValue:{}},
                  sourceschema:'',
                  sourcefield:{fieldname:'',selectedValue:{}},
                });
            },
            loadDict() {
                var dictlistwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'Categories',
                        value: 'allschemaname',
                        operation: '',
                    }]
                };
                var graphql = '{dictionarylist(where:' + JSON.stringify(dictlistwhere) +
                    '){name details {name code}}}';
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    graphql
                ).then(res => {
                    if (res.errcode == "0") {
                        var dataObj = JSON.parse(unescape(Base64.decode(res.data))).data;
                        for (var i = 0; i < dataObj.dictionarylist.length; i++) {
                            var dictName = dataObj.dictionarylist[i].name;
                            console.log(dictName);
                            this.dicts[dictName] = dataObj.dictionarylist[i].details;
                        }
                        console.log(this.dicts);
                    } else {
                        MessageTip.warnmsg('字典数据加载失败');
                    }
                });
            },
            loadData(v_page) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: [{
                        name: 'targetschema',
                        value: this.searchtargetschema,
                        operation: '',
                    }, {
                        name: 'targetschemacname',
                        value: this.searchtargetschemacname,
                        operation: '',
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{dataimportconfigsetlist(where:" + JSON.stringify(listwhere) +
                    "){id targetschema targetschemacname describe }}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.dataimportconfigsetlist = jsonData.data.dataimportconfigsetlist;
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = 50 * (jsonData.page.pageindex - 1);
                        this.loading = false;
                    }
                });
            },
            listPage(event) {
                var page = event.page;
                console.log(page);
                this.loadData(page + 1);
            },
            getrelationsourceschema(){
                var dictData=[];
                for(var i=0;i<this.currentRow.source.length;i++){
                  if(this.currentRow.source[i].sourceschema!=''){
                      for(var k=0;k<this.dicts['allschemaname'].length;k++){
                          if(this.dicts['allschemaname'][k].code==this.currentRow.source[i].sourceschema){
                              var isfind=false;
                              for(var k1=0;k1<dictData.length;k1++){
                                  if(dictData[k1].code==this.dicts['allschemaname'][k].code){
                                      isfind=true;
                                      break;
                                  }
                              }
                              if(!isfind)
                                dictData.push({name:this.dicts['allschemaname'][k].name, code:this.dicts['allschemaname'][k].code});
                              break;
                          }
                      }
                  }
                }
                console.log('getrelationsourceschema');
                console.log(this.currentRow.source);
                console.log(this.dicts['allschemaname']);
                console.log(dictData);
                return dictData;
            },
            opendetail(v_row) {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'id',
                        value: v_row.id,
                        operation: '',
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{dataimportconfigsetlist(where:" + JSON.stringify(listwhere) +
                    "){id targetschema targetschemacname describe source {sourceschema condition {sourcename field} relation {sourcename sourcefield targetname targetfield} }  }}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.currentRow={
                            id:'',
                            targetschema: '',
                            targetschemacname: '',
                            describe: '',
                            source: [],
                            relation: [],
                        };
                        var configObj= jsonData.data.dataimportconfigsetlist[0];
                        this.currentRow.id=configObj.id;
                        this.currentRow.targetschema=configObj.targetschema;
                        this.currentRow.targetschemacname=configObj.targetschemacname;
                        this.currentRow.describe=configObj.describe;
                        var selectedValueKey="";
                        for (var i = 0; i < configObj.source.length; i++) {
                            for (var k = 0; k < configObj.source[i].condition.length; k++) {
                               var sourceobj;
                               selectedValueKey="";
                               if(configObj.source[i].condition[k].sourcename!=''){
                                   sourceobj={
                                     sourceschema:configObj.source[i]['sourceschema'],
                                     sourcefield:{fieldname:configObj.source[i].condition[k].sourcename,child:{fieldname:configObj.source[i].condition[k].field},selectedValue:{}},
                                   };
                                   selectedValueKey=configObj.source[i]['sourceschema']+"-"+configObj.source[i].condition[k].sourcename+"-"+configObj.source[i].condition[k].field;
                               }else{
                                  sourceobj={
                                     sourceschema:configObj.source[i]['sourceschema'],
                                     sourcefield:{fieldname:configObj.source[i].condition[k].field,selectedValue:{}},
                                  };
                                  selectedValueKey=configObj.source[i]['sourceschema']+"-"+configObj.source[i].condition[k].field;
                              }
                              sourceobj.sourcefield.selectedValue[selectedValueKey]=true;
                              this.currentRow.source.push(sourceobj);
                           }
                           for(var k1=0;k1<configObj.source[i].relation.length; k1++){
                              var relationobj;
                              selectedValueKey="";
                              var targetselectedValueKey="";
                              var targetfieldObj={};
                              if(configObj.source[i].relation[k1].targetname!=''){
                                  targetfieldObj={fieldname:configObj.source[i].relation[k1].targetname,child:{fieldname:configObj.source[i].relation[k1].targetfield},selectedValue:{}};
                                  targetselectedValueKey=configObj.targetschema+"-"+configObj.source[i].relation[k1].targetname+"-"+configObj.source[i].relation[k1].targetfield;
                              }else{
                                  targetfieldObj={fieldname:configObj.source[i].relation[k1].targetfield,selectedValue:{}};
                                  targetselectedValueKey=configObj.targetschema+"-"+configObj.source[i].relation[k1].targetfield;
                              }
                              if(configObj.source[i].relation[k1].sourcename!=''){
                                   relationobj={
                                     sourceschema:configObj.source[i]['sourceschema'],
                                     sourcefield:{fieldname:configObj.source[i].relation[k1].sourcename,child:{fieldname:configObj.source[i].relation[k1].sourcefield},selectedValue:{}},
                                     targetfield:targetfieldObj,
                                   };
                                   selectedValueKey=configObj.source[i]['sourceschema']+"-"+configObj.source[i].relation[k1].sourcename+"-"+configObj.source[i].relation[k1].sourcefield;
                               }else{
                                  relationobj={
                                     sourceschema:configObj.source[i]['sourceschema'],
                                     sourcefield:{fieldname:configObj.source[i].relation[k1].sourcefield,selectedValue:{}},
                                     targetfield:targetfieldObj,
                                  };
                                  selectedValueKey=configObj.source[i]['sourceschema']+"-"+configObj.source[i].relation[k1].sourcefield;
                              }
                              relationobj.targetfield.selectedValue[targetselectedValueKey]=true;
                              relationobj.sourcefield.selectedValue[selectedValueKey]=true;
                              this.currentRow.relation.push(relationobj);
                           }
                        }
                        this.recordsubject = configObj.targetschemacname;
                        this.getBindDataDict(configObj.targetschema,'1',false);
                        this.editDisplay = true;
                        this.closeButLoading();
                        console.log(this.currentRow);
                    }
                });
            },
            createdetail() {
                var row = {
                    id:'',
                    targetschema: '',
                    targetschemacname: '',
                    describe: '',
                    source: [],
                    relation: [],
                };
                this.currentRow = row;
                this.recordsubject = '新建';
                this.targetbinddataNodes= ref();
                this.binddataNodes= ref();
                this.editDisplay = true;
                this.closeButLoading();
            },
            deldetail() {
                console.log(this.selectedData);
                if (this.selectedData['targetschema']) {
                    this.loading = true;
                    var id = this.selectedData['id'];
                    var title = this.selectedData['targetschemacname'];
                    this.confirm.require({
                        message: '确认要删除' + title + '?',
                        header: '确认',
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: '删除',
                        rejectLabel: '放弃',
                        accept: () => {
                            requests.graphql_requests.post(
                                '/graphql/?appkey=' + sessionStorage.getItem(
                                    "appkey"),
                                'delete{dataimportconfigset(o:' + JSON.stringify(this
                                    .selectedData) +
                                '){targetschema}}'
                            ).then(res => {
                                if (res.errcode == "0") {
                                    for (var i = 0; i < this.dataimportconfigsetlist.length; i++) {
                                        if (this.dataimportconfigsetlist[i]['id'] == id) {
                                            this.dataimportconfigsetlist.splice(i, 1);
                                            break;
                                        }
                                    }
                                    if (this.totalRecords > 0) {
                                        this.totalRecords = this.totalRecords - 1;
                                    }
                                    this.loading = false;
                                    MessageTip.successmsg('删除成功');
                                }
                            });
                        },
                        reject: () => {
                            this.loading = false;
                        }
                    });
                }
            },
            closeEdit() {
                this.closeButLoading();
                this.editDisplay = false;
            },
            submitData() {
                if (this.currentRow) {
                    this.butLoading = true;
                    var isfind=false;
                    var saveObj= {
                      id: this.currentRow.id,
                      targetschema: this.currentRow.targetschema,
                      targetschemacname: this.currentRow.targetschemacname,
                      describe: this.currentRow.describe,
                      source: [],
                    };
                    console.log('submitData-1');
                    console.log(this.currentRow);
                    for(var k=0;k<this.currentRow.source.length;k++){
                       isfind=false;
                       for(var k1=0;k1<saveObj.source.length;k1++){
                         if(saveObj.source[k1].sourceschema==this.currentRow.source[k].sourceschema){
                            isfind=true;
                            if(this.currentRow.source[k].sourcefield.fieldname!=''){
                                if(this.currentRow.source[k].sourcefield.child){
                                   saveObj.source[k1].condition.push({sourcename:this.currentRow.source[k].sourcefield.fieldname,field:this.currentRow.source[k].sourcefield.child.fieldname});
                                }else{
                                   saveObj.source[k1].condition.push({sourcename:'',field:this.currentRow.source[k].sourcefield.fieldname});
                                }
                            }
                            break;
                         }
                       }
                       if(!isfind && this.currentRow.source[k].sourcefield.fieldname!=''){
                          if(this.currentRow.source[k].sourcefield.child){
                                saveObj.source.push({
                                 sourceschema:this.currentRow.source[k].sourceschema,
                                 condition:[{sourcename:this.currentRow.source[k].sourcefield.fieldname,field:this.currentRow.source[k].sourcefield.child.fieldname}],
                                 relation:[],
                                });
                          }else{
                                saveObj.source.push({
                                 sourceschema:this.currentRow.source[k].sourceschema,
                                 condition:[{sourcename:'',field:this.currentRow.source[k].sourcefield.fieldname}],
                                 relation:[],
                                });
                          }
                       }
                    }
                    console.log('submitData-2');
                    console.log(saveObj);
                    for(var k2=0;k2<this.currentRow.relation.length;k2++){
                        isfind=false;
                        var targetnameval='';
                        var targetfieldval='';
                        var sourcenameval='';
                        var sourcefieldval='';
                       for(var k3=0;k3<saveObj.source.length;k3++){
                         if(saveObj.source[k3].sourceschema==this.currentRow.relation[k2].sourceschema){
                            isfind=true;
                            if(this.currentRow.relation[k2].sourcefield.fieldname!=''){
                                if(this.currentRow.relation[k2].sourcefield.child){
                                    sourcenameval=this.currentRow.relation[k2].sourcefield.fieldname;
                                    sourcefieldval=this.currentRow.relation[k2].sourcefield.child.fieldname;
                                }else{
                                    sourcenameval='';
                                    sourcefieldval=this.currentRow.relation[k2].sourcefield.fieldname;
                                }
                                if(this.currentRow.relation[k2].targetfield.child){
                                    targetnameval=this.currentRow.relation[k2].targetfield.fieldname;
                                    targetfieldval=this.currentRow.relation[k2].targetfield.child.fieldname;
                                }else{
                                    targetnameval='';
                                    targetfieldval=this.currentRow.relation[k2].targetfield.fieldname;
                                }
                                saveObj.source[k3].relation.push({targetname:targetnameval,targetfield:targetfieldval,sourcename:sourcenameval,sourcefield:sourcefieldval});
                            }
                            break;
                         }
                       }
                       if(!isfind && this.currentRow.relation[k2].sourcefield.fieldname!=''){
                            if(this.currentRow.relation[k2].sourcefield.child){
                                sourcenameval=this.currentRow.relation[k2].sourcefield.fieldname;
                                sourcefieldval=this.currentRow.relation[k2].sourcefield.child.fieldname;
                            }else{
                                sourcenameval='';
                                sourcefieldval=this.currentRow.relation[k2].sourcefield.fieldname;
                            }
                            if(this.currentRow.relation[k2].targetfield.child){
                                targetnameval=this.currentRow.relation[k2].targetfield.fieldname;
                                targetfieldval=this.currentRow.relation[k2].targetfield.child.fieldname;
                            }else{
                                targetnameval='';
                                targetfieldval=this.currentRow.relation[k2].targetfield.fieldname;
                            }
                            saveObj.source.push({
                                 sourceschema:this.currentRow.relation[k2].sourceschema,
                                 condition:[],
                                 relation:[{targetname:targetnameval,targetfield:targetfieldval,sourcename:sourcenameval,sourcefield:sourcefieldval}],
                            });
                       }
                    }
                    console.log('submitData-3');
                    console.log(saveObj);
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem(
                            "appkey"),
                        'mutation{dataimportconfigset(o:' + JSON.stringify(saveObj) +
                        '){targetschema}}'
                    ).then(res => {
                        if (res.errcode == "0") {
                            this.currentRow = null;
                            this.loadData(1);
                            this.closeEdit();
                            MessageTip.successmsg('保存成功');
                        } else {
                            this.closeButLoading();
                            MessageTip.errmsg('保存失败');
                        }
                    });
                }
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            sourcefieldtreeNodeSelect(v_data) {
                setTimeout(() => {
                    console.log('sourcefieldtreeNodeSelect:');
                    console.log(v_data);
                    if (v_data.sourcefield.selectedValue) {
                        var originalselectedValue = v_data.sourcefield.selectedValue;
                        console.log(originalselectedValue);
                        for (var proname in v_data.sourcefield.selectedValue) {
                            var keyArry = proname.split('-');
                            if(keyArry.length>2){
                               v_data.sourcefield = {fieldname:keyArry[1],child:{fieldname:keyArry[2]},selectedValue:{}};
                            }else{
                               v_data.sourcefield = {fieldname:keyArry[1],selectedValue:{}};
                            }
                        }
                        v_data.sourcefield['selectedValue'] = originalselectedValue;
                    }
                    console.log(v_data.sourcefield);
                }, 50);
            },
            targetsourcefieldtreeNodeSelect(v_data){
                setTimeout(() => {
                    console.log('sourcefieldtreeNodeSelect:');
                    console.log(v_data);
                    if (v_data.sourcefield.selectedValue) {
                        var originalselectedValue = v_data.sourcefield.selectedValue;
                        console.log(originalselectedValue);
                        for (var proname in v_data.sourcefield.selectedValue) {
                            var keyArry = proname.split('-');
                            if(keyArry.length>2){
                               v_data.sourcefield = {fieldname:keyArry[1],child:{fieldname:keyArry[2]},selectedValue:{}};
                            }else{
                               v_data.sourcefield = {fieldname:keyArry[1],selectedValue:{}};
                            }
                        }
                        v_data.sourcefield['selectedValue'] = originalselectedValue;
                    }
                    console.log(v_data.sourcefield);
                }, 50);
            },
            targetfieldtreeNodeSelect(v_data){
                setTimeout(() => {
                    console.log('targetfieldtreeNodeSelect:');
                    console.log(v_data);
                    if (v_data.targetfield.selectedValue) {
                        var originalselectedValue = v_data.targetfield.selectedValue;
                        console.log(originalselectedValue);
                        for (var proname in v_data.targetfield.selectedValue) {
                            var keyArry = proname.split('-');
                            if(keyArry.length>2){
                               v_data.targetfield = {fieldname:keyArry[1],child:{fieldname:keyArry[2]},selectedValue:{}};
                            }else{
                               v_data.targetfield = {fieldname:keyArry[1],selectedValue:{}};
                            }
                        }
                        v_data.targetfield['selectedValue'] = originalselectedValue;
                    }
                    console.log(v_data.targetfield);
                }, 50);
            },
            analysisBindData(v_data) {
                console.log('analysisBindData:');
                console.log(v_data);
                var bindDataStr = v_data['fieldname']
                if (v_data['child'] && v_data['child']['fieldname']) {
                    bindDataStr = bindDataStr + "." + v_data['child']['fieldname'];
                    var childObj = v_data['child']['child'];
                    if (childObj && childObj['fieldname']) {
                        bindDataStr = bindDataStr + "." + childObj['fieldname'];
                    }
                }
                return bindDataStr;
            },
            removesource(v_data) {
                for (var i = 0; i < this.currentRow.source.length; i++) {
                    if (this.currentRow.source[i].sourceschema == v_data.sourceschema && this.currentRow.source[i].sourcefield == v_data.sourcefield) {
                        this.currentRow.source.splice(i, 1);
                        break;
                    }
                }
            },
            sourceTableCellEditInit(event) {
                setTimeout(() => {
                    console.log('sourceTableCellEditInit...');
                    console.log(event);
                    if (event.field && event.field == 'sourcefield') {
                        console.log(event.field);
                        this.binddataNodes = ref();
                        console.log(event.data);
                        console.log(event.data['sourceschema']);
                        if (event.data['sourceschema'] != '') {
                            console.log(event.data['sourceschema']);
                            this.getBindDataDict(event.data['sourceschema'],'2',false);
                        }
                    }
                }, 50);
            },
            relationTableCellEditInit(event){
               this.sourceTableCellEditInit(event);
            },
            targetschemaChange(event){
                for(var k=0;k<this.dicts['allschemaname'].length;k++){
                    if(this.dicts['allschemaname'][k].code==event.value){
                        this.currentRow.targetschemacname=this.dicts['allschemaname'][k].name;
                        break;
                    }
                }
                this.getBindDataDict(event.value,'1',true);
            },
            sourceschemaChange(event) {
                this.binddataNodes = ref();
                this.getBindDataDict(event.value,'2',false);
            },
            targetsourceschemaChange(event){
               this.binddataNodes = ref();
               this.getBindDataDict(event.value,'2',false);
            },
            loadrelationTable(v_data){
              this.currentRow.relation=[];
              if(!v_data) return;
               var targetfieldObj;
              for(var i=0;i<v_data[0].children.length;i++){
                 if(v_data[0].children[i].data['objtype']=='feild'){
                      targetfieldObj={fieldname:v_data[0].children[i].data['objname'],selectedValue:{}};
                      targetfieldObj.selectedValue[v_data[0].children[i].key]=true;
                      this.currentRow.relation.push({
                          targetfield:targetfieldObj,
                          sourceschema:'',
                          sourcefield:{fieldname:'',selectedValue:{}}
                       });
                  }
                  if(v_data[0].children[i].data['objtype']=='feild_objarray'){
                      for(var k=0;k<v_data[0].children[i].children.length;k++){
                          var targetfieldval=v_data[0].children[i].data['objname']+'.'+v_data[0].children[i].children[k].data['objname'];
                          targetfieldObj={fieldname:targetfieldval,selectedValue:{}};
                          targetfieldObj.selectedValue[v_data[0].children[i].children[k].key]=true;
                          this.currentRow.relation.push({
                             targetfield:targetfieldObj,
                             sourceschema:'',
                             sourcefield:{fieldname:'',selectedValue:{}}
                           });
                      }
                  }
              }
            },
            getBindDataDict(v_schemaname,v_flag,v_loadrelationData) {
                console.log('getBindDataDict:' + v_schemaname);
                if (v_schemaname && v_schemaname != '') {
                    console.log('getBindDataDict:--1');
                    var dictSource = this.findSchemanameStructure(v_schemaname);
                    if (!dictSource) {
                        var listwhere = {
                            pageindex: 1,
                            pagesize: 50,
                            conditions: [{
                                name: 'id',
                                value: v_schemaname,
                                operation: ''
                            }]
                        };
                        requests.graphql_requests.post(
                            '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                            '{schemastructurelist(where:' + JSON.stringify(listwhere) +
                            '){id key label data {objname objcname objtype} children {id key label data {objname objcname objtype} children {id key label data {objname objcname objtype} children {id key label data {objname objcname objtype}} } } } }'
                        ).then(res => {
                            if (res.errcode == "0") {
                                var moduleObj = JSON.parse(unescape(Base64.decode(res.data))).data
                                    .schemastructurelist;
                                this.allFields.push({
                                    schemaname: v_schemaname,
                                    data: moduleObj
                                });
                                if(v_flag=='1'){
                                   this.targetbinddataNodes=moduleObj;
                                   if(v_loadrelationData)
                                     this.loadrelationTable(moduleObj);
                                }
                                if(v_flag=='2'){
                                   this.binddataNodes = moduleObj;
                                }
                                console.log('getBindDataDict5:' + this.binddataNodes);
                            } else {
                                 if(v_flag=='2'){
                                  this.binddataNodes = ref();
                                 }
                                 MessageTip.warnmsg('类型加载失败');
                            }
                        });
                    } else {
                        if(v_flag=='1'){
                           this.targetbinddataNodes=dictSource;
                           if(v_loadrelationData)
                             this.loadrelationTable(dictSource);
                        }
                        if(v_flag=='2'){
                           this.binddataNodes = dictSource;
                        }
                    }
                } else {
                    if(v_flag=='1'){
                      this.targetbinddataNodes = ref();
                    }
                    if(v_flag=='2'){
                      this.binddataNodes = ref();
                    }
                    MessageTip.warnmsg('请先选择数据源');
                }
            },
            findSchemanameStructure(v_schemaname) {
                var dictSource = null;
                for (var i = 0; i < this.allFields.length; i++) {
                    if (this.allFields[i]['schemaname'] == v_schemaname) {
                        dictSource = this.allFields[i]['data'];
                        break;
                    }
                }
                return dictSource;
            },
            closeImport() {
                this.importTemplateId = '';
                this.importDisplay = false;
            },
            exceltemplatesetImport(v_data) {
                this.importTemplateId = v_data['id'];
                this.importDisplay = true;
            },
            onCellEditComplete(event) {
                let {
                    data,
                    newValue,
                    field
                } = event;
                data[field] = newValue;
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
        },
    }
</script>